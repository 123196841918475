import { ValidatedDevice } from '@common/context/DevicesContext';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import { getValidationStatus, showTotalNlisDevices } from '@containers/Consignments/DeviceTransferHelper';
import useDebounceValue from '@effects/useDebounce';
import useDeviceManager from '@effects/useDeviceManager';
import { DeviceResponseStatus, DeviceValidationStatus } from '@utils/enums';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DeviceTable from './DeviceTable';
import DeviceTableFilters from './DeviceTableFilters';
import ValidationBanners from './ValidationBanners';

const DeviceList: React.FC<{
    consignment: ConsignmentDetailQueryResponse['consignment'];
    onRevalidate: () => void;
    activeDeviceId: string | null;
    setActiveDeviceId: (id: string | null) => void;
}> = ({ consignment, onRevalidate, activeDeviceId, setActiveDeviceId }) => {
    const { validatedDevices } = useDeviceManager();
    const [devicesByStatus, setDevicesByStatus] = useState<{
        warnings: ValidatedDevice[];
        errors: ValidatedDevice[];
        validated: ValidatedDevice[];
    }>({
        warnings: [],
        errors: [],
        validated: [],
    });
    const [sortedDevices, setSortedDevices] = useState<ValidatedDevice[]>([]);
    const [filters, setFilters] = useState({
        showIssuesOnly: false,
        isDeceased: null as boolean | null,
        species: '',
        status: '',
        searchText: '',
    });
    const debouncedSearchText = useDebounceValue(300, filters.searchText);

    const isSearchOrFilter = useCallback(() => {
        // Check filter or search criteria are set to a "truthy" value, indicating that a search or filter is actively being applied.
        return Boolean(
            debouncedSearchText || filters.isDeceased || filters.showIssuesOnly || filters.species || filters.status
        );
    }, [debouncedSearchText, filters.isDeceased, filters.showIssuesOnly, filters.species, filters.status]);

    useEffect(() => {
        const newDevicesByStatus = {
            warnings: [] as ValidatedDevice[],
            errors: [] as ValidatedDevice[],
            validated: [] as ValidatedDevice[],
        };

        // Group devices by validation status
        validatedDevices.value.forEach((device) => {
            const status = getValidationStatus(device, consignment);
            if (status === DeviceValidationStatus.WARNING) {
                newDevicesByStatus.warnings.push(device);
            } else if (status === DeviceValidationStatus.ERROR) {
                newDevicesByStatus.errors.push(device);
            } else {
                newDevicesByStatus.validated.push(device);
            }
        });

        setDevicesByStatus(newDevicesByStatus);
    }, [consignment, validatedDevices.totalDevices, validatedDevices.value]);

    useEffect(() => {
        const getFilteredDevices = (): ValidatedDevice[] => {
            let filteredDevices = filters.showIssuesOnly
                ? [...devicesByStatus.errors, ...devicesByStatus.warnings]
                : [...devicesByStatus.errors, ...devicesByStatus.warnings, ...devicesByStatus.validated];

            // Filter by search text
            if (debouncedSearchText) {
                const searchLower = debouncedSearchText.toLowerCase().trim();
                filteredDevices = filteredDevices.filter(
                    (device) =>
                        device.nLISID?.toLowerCase().includes(searchLower) ||
                        device.rFID?.toLowerCase().includes(searchLower)
                );
            }

            // Filter by species
            if (filters.species) {
                filteredDevices = filteredDevices.filter((device) => {
                    return device.species && device.species.toLowerCase().includes(filters.species.toLowerCase());
                });
            }

            // Filter by status
            if (filters.status) {
                filteredDevices = filteredDevices.filter(
                    (device) => device.status.toLocaleLowerCase() === filters.status.toLocaleLowerCase()
                );
            }

            // Filter by deceased
            if (filters.isDeceased !== null) {
                filteredDevices = filteredDevices.filter((device) => {
                    // Devices with status of 'NOT_FOUND' do not have deceased value displayed in the table.
                    return device.deceased === filters.isDeceased && device.status !== DeviceResponseStatus.NOT_FOUND;
                });
            }

            return filteredDevices;
        };

        setSortedDevices(getFilteredDevices());
    }, [debouncedSearchText, devicesByStatus, filters]);

    const totalDevices = validatedDevices.value.size;
    const totalIssues = devicesByStatus.errors.length + devicesByStatus.warnings.length;

    const handleFilterChange = useCallback((filterName: string, value: any) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));
    }, []);

    const showIssuesOnlyToggle = useMemo(
        () => devicesByStatus.errors.length > 0 || devicesByStatus.warnings.length > 0,
        [devicesByStatus.errors.length, devicesByStatus.warnings.length]
    );

    return (
        <>
            <div className="box m-b-16">
                <div className="p-t-8 p-b-16">
                    <h2 className="p-t-8 p-b-16">NLIS Device Details</h2>
                    <p>
                        Review your list below and make any minor edits by clicking 'Options', for any complex issues
                        contact{' '}
                        <a
                            href="https://www.integritysystems.com.au/identification--traceability/technical-support/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            NLIS Support
                        </a>
                        .
                    </p>
                </div>

                <div className="horizontal-line m-b-12 m-t-8" />
                {!showTotalNlisDevices(consignment, validatedDevices)
                    ? <h2 className={`p-v-16 total`}>
                        {totalIssues > 0 ? `${totalIssues}/${totalDevices} NLIS devices` : `${totalDevices} NLIS devices`}
                    </h2>
                    : <div className='p-v-16 err'>
                        <h2>{filters.showIssuesOnly
                            ? totalIssues > 0
                                ? `${totalIssues}/${totalDevices} NLIS devices`
                                : `${totalDevices} NLIS devices`
                            : validatedDevices.totalDevices
                        } NLIS devices
                        </h2>
                        <p className='p-v-16 err'>The number does not match the livestock description. Please go to {' '}
                            <a href={`${window.location.origin}/consignments/edit/${consignment?.number}/description`}>
                                livestock description
                            </a>
                            {' '}
                            to update it to correct tag number.
                        </p>
                    </div>
                }

                <ValidationBanners devicesByStatus={devicesByStatus} />

                <DeviceTableFilters
                    showToggle={showIssuesOnlyToggle}
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    consignment={consignment}
                    onRevalidate={() => onRevalidate()}
                    activeDeviceId={activeDeviceId}
                />

                <DeviceTable
                    activeDeviceId={activeDeviceId}
                    setActiveDeviceId={setActiveDeviceId}
                    validatedDevices={sortedDevices}
                    consignment={consignment}
                    isSearchOrFilter={isSearchOrFilter()}
                />

                <style jsx>
                    {`
                        @import 'mixins';
                        @import 'utils';
                        @import 'vars';

                        .horizontal-line {
                            border-top: 1px solid $color-border; /* Creates a black 1px wide line */
                        }
                        .total {
                            color: $color-secondary;
                        }
                        .err {
                            color: $color-error
                        }
                    `}
                </style>
            </div>
        </>
    );
};

export default DeviceList;
