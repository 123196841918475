/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConsignmentSpecies = "BOBBY_CALVES" | "CATTLE" | "GOAT" | "SHEEP_LAMB" | "%future added value";
export type ConsignmentStatus = "DELETED" | "DRAFT" | "LOCKED" | "SUBMITTED" | "%future added value";
export type EFormType = "EUC0" | "EUC1" | "G0517" | "HSC0" | "HSC1" | "HSC2" | "HSG0" | "HSSL0" | "HSSL1" | "LPABC0" | "LPABC1" | "LPAC0" | "LPAC1" | "LPAG0" | "LPAG2" | "LPAG3" | "LPAHRG0" | "LPASL0" | "LPASL1" | "MSAC0" | "MSAC1" | "NFASC0" | "NFASDDC0" | "NFASDDC1" | "NFASEUC0" | "NFASEUC1" | "%future added value";
export type ConsignmentRow_consignment = {
    readonly id: string;
    readonly number: string | null;
    readonly status: ConsignmentStatus;
    readonly movementDate: unknown | null;
    readonly movementTime: string | null;
    readonly species: ConsignmentSpecies;
    readonly createdBy: string;
    readonly updatedAt: unknown | null;
    readonly createdAt: unknown | null;
    readonly pdfUrl: string;
    readonly heads: string;
    readonly buyerPic: string | null;
    readonly buyerNumOfHeads: number | null;
    readonly deviceMovementID: string | null;
    readonly deviceMovementStatus: string | null;
    readonly numOfAddedDevices: string | null;
    readonly forms: ReadonlyArray<{
        readonly type: EFormType | null;
        readonly serialNumber: string | null;
    } | null> | null;
    readonly owner: {
        readonly address: {
            readonly line1: string | null;
            readonly postcode: string | null;
            readonly state: string | null;
            readonly town: string | null;
        } | null;
        readonly name: string | null;
        readonly pic: string | null;
    } | null;
    readonly origin: {
        readonly address: {
            readonly line1: string | null;
            readonly postcode: string | null;
            readonly state: string | null;
            readonly town: string | null;
        } | null;
        readonly name: string | null;
        readonly pic: string | null;
    };
    readonly destination: {
        readonly address: {
            readonly line1: string | null;
            readonly postcode: string | null;
            readonly state: string | null;
            readonly town: string | null;
        } | null;
        readonly name: string | null;
        readonly pic: string | null;
    };
    readonly consignee: {
        readonly address: {
            readonly line1: string | null;
            readonly postcode: string | null;
            readonly state: string | null;
            readonly town: string | null;
        } | null;
        readonly name: string | null;
        readonly pic: string | null;
    } | null;
    readonly " $refType": "ConsignmentRow_consignment";
};
export type ConsignmentRow_consignment$data = ConsignmentRow_consignment;
export type ConsignmentRow_consignment$key = {
    readonly " $data"?: ConsignmentRow_consignment$data;
    readonly " $fragmentRefs": FragmentRefs<"ConsignmentRow_consignment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "line1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postcode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "state",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "town",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pic",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConsignmentRow_consignment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "movementDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "movementTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "species",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pdfUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heads",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buyerPic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buyerNumOfHeads",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceMovementID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceMovementStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOfAddedDevices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormType",
      "kind": "LinkedField",
      "name": "forms",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConsignmentOwner",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsignmentownerAddress",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConsignmentOrigin",
      "kind": "LinkedField",
      "name": "origin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsignmentconsignorAddress",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConsignmentDestination",
      "kind": "LinkedField",
      "name": "destination",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsignmentdestinationAddress",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConsignmentConsignee",
      "kind": "LinkedField",
      "name": "consignee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsignmentconsigneeAddress",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ConsignmentType",
  "abstractKey": null
};
})();
(node as any).hash = 'c5d9820a733fc2c4aa5764ce594217e9';
export default node;
