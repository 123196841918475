/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConsignmentSpecies = "BOBBY_CALVES" | "CATTLE" | "GOAT" | "SHEEP_LAMB" | "%future added value";
export type ConsignmentStatus = "DELETED" | "DRAFT" | "LOCKED" | "SUBMITTED" | "%future added value";
export type EFormType = "EUC0" | "EUC1" | "G0517" | "HSC0" | "HSC1" | "HSC2" | "HSG0" | "HSSL0" | "HSSL1" | "LPABC0" | "LPABC1" | "LPAC0" | "LPAC1" | "LPAG0" | "LPAG2" | "LPAG3" | "LPAHRG0" | "LPASL0" | "LPASL1" | "MSAC0" | "MSAC1" | "NFASC0" | "NFASDDC0" | "NFASDDC1" | "NFASEUC0" | "NFASEUC1" | "%future added value";
export type EQuestionAnswer = "BOOLEAN" | "DATE" | "DATE_MONTH" | "DATE_TIME" | "DISPLAY" | "FILE" | "IMAGE" | "MULTIPLE_CHOICE" | "NUMBER" | "REPEATABLE" | "SIGNATURE" | "SINGLE_CHOICE" | "STRING" | "%future added value";
export type ConsignmentEditConsignmentFragment = {
    readonly id: string;
    readonly number: string | null;
    readonly status: ConsignmentStatus;
    readonly movementDate: unknown | null;
    readonly movementTime: string | null;
    readonly species: ConsignmentSpecies;
    readonly createdBy: string;
    readonly updatedAt: unknown | null;
    readonly heads: string;
    readonly pdfUrl: string;
    readonly basedOn: string | null;
    readonly deviceMovementID: string | null;
    readonly deviceMovementStatus: string | null;
    readonly numOfNlisDevices: string | null;
    readonly numOfRumenDevices: string | null;
    readonly numOfAddedDevices: string | null;
    readonly owner: {
        readonly address: {
            readonly line1: string | null;
            readonly postcode: string | null;
            readonly state: string | null;
            readonly town: string | null;
        } | null;
        readonly name: string | null;
        readonly pic: string | null;
    } | null;
    readonly origin: {
        readonly address: {
            readonly line1: string | null;
            readonly postcode: string | null;
            readonly state: string | null;
            readonly town: string | null;
        } | null;
        readonly name: string | null;
        readonly pic: string | null;
    };
    readonly destination: {
        readonly address: {
            readonly line1: string | null;
            readonly postcode: string | null;
            readonly state: string | null;
            readonly town: string | null;
        } | null;
        readonly name: string | null;
        readonly pic: string | null;
    };
    readonly consignee: {
        readonly address: {
            readonly line1: string | null;
            readonly postcode: string | null;
            readonly state: string | null;
            readonly town: string | null;
        } | null;
        readonly name: string | null;
        readonly pic: string | null;
    } | null;
    readonly forms: ReadonlyArray<{
        readonly type: EFormType | null;
        readonly serialNumber: string | null;
    } | null> | null;
    readonly answers: ReadonlyArray<{
        readonly questionId: string | null;
        readonly index: unknown | null;
        readonly value: string | null;
    } | null> | null;
    readonly questions: ReadonlyArray<{
        readonly readOnly: boolean | null;
        readonly text: string;
        readonly id: string | null;
        readonly order: number;
        readonly type: EQuestionAnswer | null;
        readonly help: string | null;
        readonly tip: string | null;
        readonly triggers: ReadonlyArray<{
            readonly questionId: string | null;
            readonly value: string | null;
        } | null> | null;
        readonly acceptableAnswers: ReadonlyArray<{
            readonly displayName: string | null;
            readonly value: string | null;
        } | null> | null;
        readonly validators: ReadonlyArray<{
            readonly type: string | null;
            readonly parameter: string | null;
            readonly errorMessage: string | null;
        } | null> | null;
        readonly childQuestions: ReadonlyArray<{
            readonly readOnly: boolean | null;
            readonly text: string;
            readonly id: string | null;
            readonly order: number;
            readonly type: EQuestionAnswer | null;
            readonly help: string | null;
            readonly tip: string | null;
            readonly triggers: ReadonlyArray<{
                readonly questionId: string | null;
                readonly value: string | null;
            } | null> | null;
            readonly acceptableAnswers: ReadonlyArray<{
                readonly displayName: string | null;
                readonly value: string | null;
            } | null> | null;
            readonly validators: ReadonlyArray<{
                readonly type: string | null;
                readonly parameter: string | null;
                readonly errorMessage: string | null;
            } | null> | null;
            readonly childQuestions: ReadonlyArray<{
                readonly readOnly: boolean | null;
                readonly text: string;
                readonly id: string | null;
                readonly order: number;
                readonly type: EQuestionAnswer | null;
                readonly help: string | null;
                readonly tip: string | null;
                readonly triggers: ReadonlyArray<{
                    readonly questionId: string | null;
                    readonly value: string | null;
                } | null> | null;
                readonly acceptableAnswers: ReadonlyArray<{
                    readonly displayName: string | null;
                    readonly value: string | null;
                } | null> | null;
                readonly validators: ReadonlyArray<{
                    readonly type: string | null;
                    readonly parameter: string | null;
                    readonly errorMessage: string | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
    } | null> | null;
    readonly declaration: {
        readonly accept: boolean | null;
        readonly address: {
            readonly line1: string | null;
            readonly postcode: string | null;
            readonly state: string | null;
            readonly town: string | null;
        } | null;
        readonly certificateNumber: string | null;
        readonly date: unknown | null;
        readonly email: string | null;
        readonly fullName: string | null;
        readonly phone: string | null;
        readonly signature: string | null;
    } | null;
    readonly " $refType": "ConsignmentEditConsignmentFragment";
};
export type ConsignmentEditConsignmentFragment$data = ConsignmentEditConsignmentFragment;
export type ConsignmentEditConsignmentFragment$key = {
    readonly " $data"?: ConsignmentEditConsignmentFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ConsignmentEditConsignmentFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "line1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postcode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "state",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "town",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pic",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readOnly",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "help",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tip",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "QuestionTriggerType",
  "kind": "LinkedField",
  "name": "triggers",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "AcceptableAnswerType",
  "kind": "LinkedField",
  "name": "acceptableAnswers",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "QuestionValidatorType",
  "kind": "LinkedField",
  "name": "validators",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parameter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorMessage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConsignmentEditConsignmentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "movementDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "movementTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "species",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heads",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pdfUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basedOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceMovementID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceMovementStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOfNlisDevices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOfRumenDevices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOfAddedDevices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConsignmentOwner",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsignmentownerAddress",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConsignmentOrigin",
      "kind": "LinkedField",
      "name": "origin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsignmentconsignorAddress",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConsignmentDestination",
      "kind": "LinkedField",
      "name": "destination",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsignmentdestinationAddress",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConsignmentConsignee",
      "kind": "LinkedField",
      "name": "consignee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsignmentconsigneeAddress",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormType",
      "kind": "LinkedField",
      "name": "forms",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerType",
      "kind": "LinkedField",
      "name": "answers",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "index",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionType",
      "kind": "LinkedField",
      "name": "questions",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v8/*: any*/),
        (v0/*: any*/),
        (v9/*: any*/),
        (v4/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionType",
          "kind": "LinkedField",
          "name": "childQuestions",
          "plural": true,
          "selections": [
            (v7/*: any*/),
            (v8/*: any*/),
            (v0/*: any*/),
            (v9/*: any*/),
            (v4/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionType",
              "kind": "LinkedField",
              "name": "childQuestions",
              "plural": true,
              "selections": [
                (v7/*: any*/),
                (v8/*: any*/),
                (v0/*: any*/),
                (v9/*: any*/),
                (v4/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConsignmentDeclaration",
      "kind": "LinkedField",
      "name": "declaration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accept",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PropertyAddress",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "certificateNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signature",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ConsignmentType",
  "abstractKey": null
};
})();
(node as any).hash = '3979f5f5a92057ab126dbdc17220da85';
export default node;
